// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { borderTop, borderEnd, parseStyleProps, } from '@haaretz/htz-css-tools';
import log from 'loglevel';
import gql from 'graphql-tag';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import ActionButtons from '../ActionButtons/ActionButtons';
import Query from '../ApolloBoundary/Query';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
import useIsLabel from '../../hooks/Page/useIsLabel';
import usePrint from '../../hooks/Page/usePrint';


const SHARE_BAR_QUERY: Object = gql`
  query shareBarData {
    isMouseStory @client
  }
`;

type Props = {
  title: string,
  canonicalUrl: string,
  miscStyles: ?StyleProps,
  isLabel: boolean,
};
type MobileShareBarProps = {
  kicker?: ?string,
  title: string,
  canonicalUrl: string,
};

const wrapperStyles = ({ theme, miscStyles, }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: theme.fontStacks[theme.framedFont],
  extend: [
    borderTop({
      width: '1px',
      lines: 2,
      style: 'solid',
      color: theme.color('shareBar', 'borderTop'),
    }),
    theme.mq({ until: 's', }, { display: 'none', }),
    theme.mq({ until: 'l', }, { marginTop: '2rem', }),
    theme.mq({ from: 'l', }, { marginTop: '3rem', }),
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

const getMobileActions = (theme, isLabel) => {
  const buttonStyles = {
    color: theme.color('mobileNav', 'barIcons'),
    paddingInlineEnd: 0,
    paddingInlineStart: 0,
    ':hover': {
      color: theme.color('mobileNav', 'barIcons'),
    },
  };
  return {
    right: [
      {
        ...theme.shareBarI18n.mobileFirstShareButtons[0],
        buttonStyles,
      },
      {
        ...theme.shareBarI18n.mobileFirstShareButtons[1],
        buttonStyles,
      },
    ],
    left: [
      !theme.mobileReadingList || isLabel
        ? {
          name: theme.shareBarI18n.mobileChangingShareButton,
          buttonStyles,
        }
        : {
          name: 'save',
          buttonStyles,
          buttonProps: {
            inMobileMenu: true,
          },
        },
      {
        name: 'mail',
        buttonStyles,
      },
    ],
  };
};

const mobileShareWrapperStyle = ({ theme, }) => ({
  color: theme.color('mobileNav', 'barIcons'),
  width: '100%',
  position: 'fixed',
  zIndex: theme.getZIndex([ 'masthead', 'mobile', ], 2),
  bottom: '0',
  paddingBottom: 'env(safe-area-inset-bottom, 0)',
  start: '0',
  display: 'grid',
  gridTemplateAreas: `
  ".     menuButton ."
  "right menuButton left"
  ".     menuButton ."
  `,
  gridTemplateColumns: '1fr 11rem 1fr',
  gridTemplateRows: '0 minmax(6rem, 1fr) 0',
  gridRowGap: '2rem',
  '-webkit-tap-highlight-color': 'transparent',
  ...theme.mq({ from: 's', }, { display: 'none', }),
});

MobileShareBar.defaultProps = { kicker: null, };
export function MobileShareBar({
  kicker,
  title,
  canonicalUrl, }: MobileShareBarProps): React.Node {
  const { css, theme, } = useFela();
  const isLabel = useIsLabel();
  const mobileActions = getMobileActions(theme, isLabel);

  const mobileSideStyle = side => ({
    gridArea: side,
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    '& > *:not(:last-child)': {
      ...borderEnd(1, 'solid', theme.color('neutral', '-6')),
    },
  });

  return (
    <WebViewExclude>
      <div className={css(mobileShareWrapperStyle)} data-test="shareBarMobile">
        <ActionButtons
          miscStyles={mobileSideStyle('right')}
          elementName={title}
          elementUrl={canonicalUrl}
          buttons={mobileActions.right}
          size={4.5}
        />
        <ActionButtons
          miscStyles={mobileSideStyle('left')}
          elementName={title}
          elementUrl={canonicalUrl}
          buttons={mobileActions.left}
          size={4.5}
        />
      </div>
    </WebViewExclude>
  );
}

ShareBar.defaultProps = { miscStyles: null, isLabel: false, };
function ShareBar({
  title,
  canonicalUrl,
  miscStyles,
  isLabel, }: Props): React.Node {
  const { css, theme, } = useFela({ miscStyles, });
  const { isPrint, } = usePrint();

  if (isPrint) {
    return null;
  }

  return (
    <Query query={SHARE_BAR_QUERY}>
      {({ error, loading, data, }) => {
        if (error) {
          log.error(error);
          return null;
        }
        if (loading) return null;
        const { isMouseStory, } = data;
        const shareButtons = theme.shareBarI18n.shareButtons({ theme, isMouseStory, isLabel, });
        return (
          <WebViewExclude>
            <MobileShareBar isLabel={isLabel} title={title} canonicalUrl={canonicalUrl} />
            <div className={css(wrapperStyles)} data-test="shareBar">
              <ActionButtons
                elementName={title}
                elementUrl={canonicalUrl}
                buttons={shareButtons.startButtons}
                size={4}
              />
              <div className={css({ display: 'flex', })}>
                <ActionButtons
                  elementName={title}
                  elementUrl={canonicalUrl}
                  globalButtonsStyles={{
                    color: theme.color('shareBar', 'button'),
                    ':hover': {
                      color: theme.color('shareBar', 'buttonHover'),
                    },
                  }}
                  globalIconsStyles={{
                    color: theme.color('shareBar', 'button'),
                    ':hover': {
                      color: theme.color('shareBar', 'buttonHover'),
                    },
                  }}
                  buttons={shareButtons.endButtons}
                  size={4.5}
                />
              </div>

            </div>
          </WebViewExclude>
        );
      }
      }
    </Query>
  );
}

export default ShareBar;
